import React, { useEffect } from 'react'
import {
    Nav,
    Button,
    Offcanvas, Navbar, NavDropdown, Form, Container,
    Row, Col, Carousel, Card,
    CardTitle, Tab, Breadcrumb
} from "react-bootstrap";
import blogd1 from "../assets/images/blogd1.png";
import blog2 from "../assets/images/blog2.png";
import genivislogo from "../assets/images/genivislogo.png";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import x from "../assets/images/x.png";
import insta from "../assets/images/insta.png";
import fb from "../assets/images/fb.png";
import youtube from "../assets/images/youtube.png";
import linkedin from "../assets/images/linkedin.png";
import appstore from "../assets/images/appstore.png";
import imageplay from "../assets/images/imageplay.png";
import recommended3 from "../assets/images/recommended3.png";
import recommended2 from "../assets/images/recommended2.png";
import recommended1 from "../assets/images/recommended1.png";
import recommended4 from "../assets/images/recommended4.png";
import recommended5 from "../assets/images/recommended5.png";
import { useNavigate } from 'react-router-dom';
import tag from "../assets/images/tag.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faShare, faShareNodes, faTag } from '@fortawesome/free-solid-svg-icons';
import Layout from './Layout';
// import { faTag } from '@fortawesome/free-regular-svg-icons';
export default function BlogDetails() {
    const navigate = useNavigate();
    const boosterDir = () => {
        navigate('/booster');
    };
    const gettingfamilierDir = () => {
        navigate('/gettingfamilier');
    };
    return (
        <div>
            <Layout>
                <Container fluid>
                    <div className='blogdetails mb-5 pt-4'>
                        <Row className='justify-content-center'>
                            <Col lg="9" sm="12" xs="12">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="blogs">Blogs</Breadcrumb.Item>
                                    <Breadcrumb.Item href="blogdetails" style={{ color: "#888" }}>
                                        Benefits Of Generic Medicines
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <p className='head'>
                                    Benefits Of Generic Medicines
                                </p>
                                <p className='bluestyle'>Generic Pharmacy Business</p>
                                <p className='subtext'>Published October 12, 2021 by the Genivis Team</p>
                                <Row>
                                    <Col lg="8" md="8" sm="12" xs="12">
                                        <img src={blogd1} className='imgstyle' />
                                        <Row className='mt-3'>
                                            <Col lg="12" className=' d-flex my-auto'>
                                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                                </a>
                                                <div className='d-flex shareit'>
                                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className='headtitle'>What Are Generic Medicines?</p>
                                        <p className='textstyle mt-3'>Generic medicines are pharmaceutical drugs that are equivalent to
                                            brand-name drugs in dosage form, strength, quality, performance characteristics, intended
                                            use, safety and route of administration. Unlike their branded counterparts, generic medicines
                                            are marketed under their chemical names without extensive advertising. This makes them a cost-effective
                                            alternative for consumers while maintaining the same therapeutic benefits.
                                        </p>
                                        <p className='headtitle'>Benefits Of Generic Medicines</p>
                                        <p className='subhead'>FDA Approval :</p>
                                        <p className='textstyle mt-3'>It is essential that generic medicine manufacturers meet the same high
                                            standards as their brand-name equivalents to secure approval from the U.S. Food and Drug Administration (FDA).
                                            The FDA mandates that generic medicine companies ensure their products are substituted effectively and provide
                                            identical clinical benefits to brand-name drugs. This rigorous approval process guarantees that generic medicines
                                            are safe, effective, and reliable for consumer use.
                                        </p>
                                        <p className='subhead'>Effectiveness :</p>
                                        <p className='textstyle mt-3'>Generic medicines are required to perform and provide similar clinical benefits to their
                                            brand-name counterparts. Generic medicine manufacturers use active pharmaceutical ingredients to ensure the safety
                                            and efficacy of their products. This bioequivalence means that consumers can trust generic medicines to deliver
                                            the same health outcomes as branded drugs.
                                        </p>
                                        <p className='subhead'>Cost-Effciency :</p>
                                        <p className='textstyle mt-3'>
                                            One of the most significant advantages of generic medicines is their cost efficiency. Generic drugs can be up to 85%
                                            cheaper than their brand-name equivalents, making them a crucial tool in reducing drug prices and increasing access
                                            to affordable healthcare.
                                        </p>
                                        <p className='textstyle mt-3'>
                                            For instance, a strip of 15 Crocin tablets, a popular brand-name medication for fever, costs around Rs 30. In comparison,
                                            a strip of 15 paracetamol tablets, which is the generic equivalent, costs approximately Rs 14. This substantial price
                                            difference makes generic medicines an attractive option for cost-conscious consumers.
                                        </p>
                                        <p className='subhead'> Accessibility :</p>
                                        <p className='textstyle mt-3'>
                                            Finding generic medicine equivalents to brand-name drugs is not complicated. In India, there is a wide variety of well-known
                                            medications with generic counterparts readily available. Over 60,000 generic drugs are accessible to Indian consumers, making
                                            it easier for patients to obtain necessary treatments.
                                        </p>
                                        <p className='textstyle mt-3'>
                                            For example, the Indian government launched the "Jan Aushadhi" program in 2008, which translates to “Medicine for People.” This
                                            initiative aimed to provide unbranded quality medicines, known as generic medicines, to the poor. The program has significantly
                                            increased access to cost-effective healthcare and boosted confidence among citizens regarding the availability of affordable medication.
                                        </p>
                                        <p className='headtitle'>Your Trusted Partner in Generic Medicines</p>
                                        <p className='textstyle mt-3'>
                                            At Genivis Remedies, we are committed to providing high-quality generic medicines that meet rigorous FDA standards.
                                            Our products are designed to be cost-effective without compromising on safety or efficacy.
                                        </p>
                                        <p className='subhead'>Conclusion :</p>
                                        <p className='textstyle mt-3'>
                                            Generic medicines offer numerous benefits, including FDA approval, effectiveness, cost-efficiency and easy accessibility.
                                            By providing the same clinical benefits as brand-name drugs at a fraction of the cost, generic medicines play a vital role
                                            in improving public health and reducing healthcare expenses. Programs like Jan Aushadhi highlight the importance of making
                                            these affordable medications available to all, ensuring that everyone has access to essential treatments.
                                        </p>
                                        <hr />
                                        <Row className='mt-3'>
                                            <Col lg="12" className=' d-flex my-auto'>
                                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                                </a>
                                                <div className='d-flex shareit'>
                                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" md="4" sm="12" xs="12">
                                        <p className='recommended'>
                                            Recommended Articles
                                        </p>
                                        <Row onClick={gettingfamilierDir}>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended3} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Getting Familiar with Generic Medicine</p>
                                                <p className='bluestyle'>
                                                    Generic Pharmacy Business
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3' onClick={boosterDir}>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended3} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Booster Generic Awareness</p>
                                                <p className='bluestyle'>
                                                    Generic Pharmacy Business
                                                </p>
                                            </Col>
                                        </Row>
                                        {/* <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended4} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Add Tomatoes to your Salad Diet</p>
                                                <p className='bluestyle'>
                                                    Food
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended5} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>10 Essential Health Tips for a Healthy Lifestyle</p>
                                                <p className='bluestyle'>
                                                    Lifestyle
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended1} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Managing Diabetes: Empowering Strategies for a Healthier Life</p>
                                                <p className='bluestyle'>
                                                    Personal Care
                                                </p>
                                            </Col>
                                        </Row> */}
                                        <Row className='mt-5'>
                                            <Col lg="12">
                                                <p className='recommended'>
                                                    Explore More Tags
                                                </p>
                                            </Col>
                                            <Col>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    HealthCare
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Tips
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Personal Care
                                                </Button>

                                            </Col>

                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Generic Pharmacy Medicine
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Food
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>

                </Container>
            </Layout>
            {/* <div className='footer'>
                <Row className='justify-content-center'>
                    <Col lg="9">
                        <hr />
                        <Row>
                            <Col lg="3">
                                <img src={genivislogo} className='footerlogo mb-4' />
                                <p className='footertxt'>Genivis Remedies (I) Pvt. Ltd., Marvel Feugo,
                                    Office No. 6070, Opp. Seasons Mall,
                                    Magarpatta Road, Hadapsar, Pune, 411028.</p>
                                <Row className="mt-5">
                                    <Col lg="1">
                                        <img src={call} className="icon-style" />
                                    </Col>
                                    <Col lg="11">
                                        <p className="contact-style">+919145009191</p>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col lg="1">
                                        <img src={gmail} className="icon-style" />
                                    </Col>
                                    <Col lg="11">
                                        <p className="contact-style">prm@genivis.in</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="1"></Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Company</h6>
                                    <li className='footertxt mb-2'>Home</li>
                                    <li className='footertxt mb-2'>About Us</li>
                                    <li className='footertxt mb-2'>Gallery</li>
                                    <li className='footertxt mb-2'>Careers</li>
                                    <li className='footertxt mb-2'>Contact Us</li>
                                    <li className='footertxt'>Blogs</li>
                                </ul>
                            </Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Our Services</h6>
                                    <li className='footertxt mb-2'>Our Products</li>
                                    <li className='footertxt mb-2'>Comapre Medicine</li>
                                    <li className='footertxt mb-2'>Locate Nearest Store</li>
                                    <li className='footertxt mb-2'>Know Generics</li>

                                </ul>
                            </Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Policies</h6>
                                    <li className='footertxt mb-2'>Privacy Policy</li>
                                    <li className='footertxt mb-2'>Terms & Conditions</li>
                                    <li className='footertxt mb-2'>Refund & Retun Policy</li>
                                </ul>
                            </Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Follow Us</h6>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faFacebook} className='me-2' />
                                        Facebook</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faInstagram} className='me-2' />
                                        Instagram</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faLinkedin} className='me-2' />
                                        Linkedin</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faYoutube} className='me-2' />
                                        Youtube</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faSquareXTwitter} className='me-2' />
                                        X(Twitter)
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col lg="12" className='justify-content-center'>
                                <div className='footerdiv text-center'>
                                    <Row className='justify-content-center '>
                                        <Col lg="4" className='text-end'>
                                            <p>Download the app by clicking the link:</p>
                                        </Col>
                                        <Col lg="4" className='text-start '>
                                            <img src={imageplay} className='footerimg me-3' />
                                            <img src={appstore} className='footerimg' />
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                            <Col lg="12" className='justify-content-center'>
                                <p className='mt-2'>©Genivis Remedies (I) Pvt. Ltd. All rights reserved.</p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div> */}
        </div>
    )
}
