import React from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
export default function Advertisement() {
    return (
        <div>
            <div className='mb-5'>
                <p className='subhead text-start'>Advertisement Videos</p>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/E0MUS9YJSp0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/YQJaPsRHlBc"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/DJpRXAVNSQg"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/zFEBjzCypPM"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
