import React, { useState } from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
// import Layout from './Layout';
import p4 from "../assets/images/pressr/p4.jpg";
import p1 from "../assets/images/pressr/p1.jpg";
import p2 from "../assets/images/pressr/p2.jpg";
import p3 from "../assets/images/pressr/p3.jpg";
import p5 from "../assets/images/pressr/p5.jpg";
import p6 from "../assets/images/pressr/p6.jpg";
import p7 from "../assets/images/pressr/p7.jpg";
import press1 from "../assets/images/pressr/press1.jpeg";
import press22 from "../assets/images/pressr/press22.jpeg";
import press33 from "../assets/images/pressr/press33.jpeg";
import press44 from "../assets/images/pressr/press44.jpeg";
import press66 from "../assets/images/pressr/press66.jpeg";
export default function PressRelease() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    return (
        <div>
            <p className='subhead text-start'>Press Release</p>
            <div className='mb-4'>
                <Row className='justify-content-center'>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p4} className='img-style' onClick={() => handleShow(p4)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={press1} className='img-style' onClick={() => handleShow(press1)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p1} className='img-style' onClick={() => handleShow(p1)} />
                    </Col>
                </Row>
                <Row className='justify-content-center mt-4'>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p2} className='img-style' onClick={() => handleShow(p2)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p3} className='img-style' onClick={() => handleShow(p3)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p5} className='img-style' onClick={() => handleShow(p5)} />
                    </Col>
                </Row>
                <Row className='justify-content-center mt-4'>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p6} className='img-style' onClick={() => handleShow(p6)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={p7} className='img-style' onClick={() => handleShow(p7)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={press22} className='img-style' onClick={() => handleShow(press22)} />
                    </Col>
                </Row>
                <Row className='justify-content-center mt-4'>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={press33} className='img-style' onClick={() => handleShow(press33)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={press44} className='img-style' onClick={() => handleShow(press44)} />
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="6">
                        <img src={press66} className='img-style' onClick={() => handleShow(press66)} />
                    </Col>
                </Row>
                <Modal show={show} onHide={handleClose} centered className='modal-lg'>
                    <Modal.Body className='text-center'>
                        <img src={selectedImage} alt="Selected" className='img-fluid' />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
