import React, { useState } from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import award1 from "../assets/images/awardsnew/award1.jpg";
import award2 from "../assets/images/awardsnew/award2.jpg";
import award3 from "../assets/images/awardsnew/award3.jpg";
import award4 from "../assets/images/awardsnew/award4.jpg";
export default function AwardsRecognition() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    return (
        <div>
            <p className='subhead text-start'>Award & Recognition's</p>
            <div className='mb-4'>
                <Row className='justify-content-center mb-4'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/TMNJc4SQTnQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <img src={award1} className='awardimg' onClick={() => handleShow(award1)} />
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <img src={award2} className='awardimg' onClick={() => handleShow(award2)} />
                    </Col>

                </Row>

                <Row className='justify-content-center mt-4'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <img src={award3} className='awardimg' onClick={() => handleShow(award3)} />
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <img src={award4} className='awardimg' onClick={() => handleShow(award4)} />
                    </Col>

                </Row>

                <Modal show={show} onHide={handleClose} centered className='modal-lg'>
                    <Modal.Body className='text-center'>
                        <img src={selectedImage} alt="Selected" className='img-fluid' />
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    )
}
