import React, { useEffect, useState, useRef } from "react";
import Layout from "./Layout";
import { useLocation, useParams } from "react-router-dom";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import business from "../assets/images/business.png";
import directions from "../assets/images/directions.png";
import config from "config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Button, Row, Col, Container, Modal, Form, Card, Spinner
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHandPointDown,
    faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import MyMapComponent from "./MyMapComponent";

export default function BecomeBusinessPartner(props) {
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [statesList, setStatesList] = useState([]);
    const [errors, setErrors] = useState({});

    let states = [];
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        mobileno: '',
        email: '',
        state: '',
        pincode: '',
        subject: '' // Add subject field here
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const error = validateField(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: error
        });
    };
    const validateField = (name, value) => {
        let error;
        switch (name) {
            case 'firstname':
            case 'lastname':
                if (!value) {
                    error = 'Name is required';
                } else if (!/^[a-zA-Z ]+$/.test(value)) {
                    error = 'Name can only contain letters and spaces';
                }
                break;
            case 'mobileno':
                if (!value) {
                    error = 'Mobile number is required';
                } else if (!/^[0-9]{10}$/.test(value)) {
                    error = 'Mobile number must be 10 digits';
                }
                break;
            case 'email':
                if (!value) {
                    error = 'Email is required';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = 'Email is invalid';
                }
                break;
            case 'pincode':
                if (!value) {
                    error = 'Pincode is required';
                } else if (!/^[0-9]{6}$/.test(value)) {
                    error = 'Pincode must be 6 digits';
                }
                break;
            default:
                break;
        }
        return error;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const {
            firstname,
            lastname,
            mobileno,
            email,
            state,
            pincode,
            subject,
        } = e.target.elements;
        let details = {
            firstname: firstname.value,
            lastname: lastname.value,
            mobileno: mobileno.value,
            email: email.value,
            state: state.value,
            pincode: pincode.value,
            subject: subject.value,
        };
        // Check for errors before submitting
        const validationErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) {
                validationErrors[key] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setLoading(false);
            return;
        }
        try {
            let response = await fetch(`${config.emailServerUrl}/contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", // Corrected header
                },
                body: JSON.stringify(details),
            });

            let result = await response.json();
            console.log("result.status", result.status);
            // formRef.resetForm();
            setFormData({
                firstname: '',
                lastname: '',
                mobileno: '',
                email: '',
                state: '',
                pincode: '',
                subject: ''
            });
            formRef.current.reset();
            setStatesList([]);
            toast.success('Form Submitted Successfully', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.error("Error:", error);
            toast.error('Form Submission Failed', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setLoading(false);
        }
    };
    const sendMail = async (data) => {
        // Simulating mail sending
        return new Promise((resolve) => setTimeout(resolve, 2000));
    };
    const [activeButton, setActiveButton] = useState("franchiseInauguration");
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const { type } = useParams();
    // const type = useParams();
    const location = useLocation();
    // const { type } = useParams();
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage("");
    };
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };
    useEffect(() => {
        resetFormData();
    }, [location, type]);

    const sub = "Inquiry form for " + type + " Partner!";

    const getStateList = (pincode) => { 
        fetch('https://api.postalpincode.in/pincode/' + pincode)
            .then((res) => res.json())
            .then((data) => {
                let states = [];
                data[0]['PostOffice'].length > 0 &&
                    states.push(data[0]['PostOffice'][0].State)

                setStatesList(states)

            })
            .catch((error) => console.error('Error fetching photos:', error));
    }

    const resetFormData = () => {
        setFormData({
            firstname: '',
            lastname: '',
            mobileno: '',
            email: '',
            state: '',
            pincode: ''
        });
        formRef.current.reset();
        setStatesList([]);
        setErrors({});
    }

    const renderContent = (type) => {
        switch (type) {
            //Franchise Inauguration
            case "Franchise":
                return (
                    <>
                        <p className="subhead text-start pdmob">Franchise Partner</p>
                        <div className="mb-5 pdmob">
                            <Card
                                style={{
                                    background: "#a8b3d9",
                                    border: "transparent",
                                    borderRadius: "10px",
                                    padding: "10px 5px",
                                }}
                            >
                                <Card.Body>
                                    {/* onSubmit={handleSubmit} */}
                                    <Form
                                        onSubmit={handleSubmit}
                                        id="franchisepartner"
                                        ref={formRef}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="firstname"
                                                    id="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.firstname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstname}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-none">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="subject"
                                                    id="subject"
                                                    value={sub}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className="fieldstyle"
                                                    name="lastname"
                                                    id="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.lastname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastname}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className="lblstyle">Mobile No.</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="2" md="2" sm="2" xs="2" className="pe-0">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="+91"
                                                        className="fieldstyle"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="10" md="10" sm="10" xs="10">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Mobile No"
                                                        className="fieldstyle"
                                                        name="mobileno"
                                                        id="mobileno"
                                                        value={formData.mobileno}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileno}
                                                        maxLength="10"
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.mobileno}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Label className="lblstyle">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    className="fieldstyle"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Pincode</Form.Label>
                                                <Form.Control
                                                    placeholder="Pincode"
                                                    className="fieldstyle"
                                                    name="pincode"
                                                    id="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pincode}
                                                    maxLength="6"
                                                    autoComplete="off"
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        getStateList(formData.pincode);
                                                    }}

                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.pincode}
                                                </Form.Control.Feedback>    
                                            </Col>

                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">State</Form.Label>
                                                <Form.Select
                                                    // defaultValue="Choose..."
                                                    className="fieldstyle"
                                                    value={formData.state}
                                                    id="state"
                                                    onChange={handleChange}
                                                    name="state" 
                                                >
                                                    {statesList.length == 0 ?
                                                        <option value="" disabled>Choose...</option>
                                                        :
                                                        statesList.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg={4} md={4} sm={6} xs={6} className='pe-0'>
                                                <Button className="franchisebtn me-2 w-100" type="submit" disabled={loading}>
                                                    {loading ? (
                                                        <div className="spinner-container">
                                                            <Spinner as="span" animation="border" role="status" aria-hidden="true" className="custom-spinner" />
                                                            Submiting
                                                        </div>
                                                    ) : (

                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col lg={4} md={4} sm={6} xs={6}>
                                                <Button className='cancel w-100' type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        resetFormData();
                                                    }}
                                                    disabled={loading}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </>
                );
            //training
            case "District":
                return (
                    <>
                        <p className="subhead text-start pdmob">
                            District Franchise Partner
                        </p>
                        <div className="mb-5 pdmob">
                            <Card
                                style={{
                                    background: "#a8b3d9",
                                    border: "transparent",
                                    borderRadius: "10px",
                                    padding: "10px 5px",
                                }}
                            >
                                <Card.Body>
                                    {/* onSubmit={handleSubmit} */}
                                    <Form
                                        onSubmit={handleSubmit}
                                        id="franchisepartner"
                                        ref={formRef}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="firstname"
                                                    id="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.firstname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstname}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-none">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="subject"
                                                    id="subject"
                                                    value={sub}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className="fieldstyle"
                                                    name="lastname"
                                                    id="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.lastname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastname}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className="lblstyle">Mobile No.</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="2" md="2" sm="2" xs="2" className="pe-0">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="+91"
                                                        className="fieldstyle"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="10" md="10" sm="10" xs="10">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Mobile No"
                                                        className="fieldstyle"
                                                        name="mobileno"
                                                        id="mobileno"
                                                        value={formData.mobileno}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileno}
                                                        maxLength="10"
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.mobileno}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Label className="lblstyle">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    className="fieldstyle"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Pincode</Form.Label>
                                                <Form.Control
                                                    placeholder="Pincode"
                                                    className="fieldstyle"
                                                    name="pincode"
                                                    id="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pincode}
                                                    maxLength="6"
                                                    autoComplete="off"
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        getStateList(formData.pincode);
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.pincode}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">State</Form.Label>
                                                {/* <Form.Select
                                                    defaultValue="Choose..."
                                                    className="fieldstyle"
                                                    value={formData.state}
                                                    id="state"
                                                    onChange={handleChange}
                                                    name="state"
                                                >
                                                    <option value="">Choose...</option>
                                                    {states.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select> */}
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    className="fieldstyle"
                                                    value={formData.state}
                                                    id="state"
                                                    onChange={handleChange}
                                                    name="state"
                                                >
                                                    {statesList.length == 0 ?
                                                        <option value="" disabled>Choose...</option>
                                                        :
                                                        statesList.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} md={4} sm={6} xs={6} className='pe-0'>
                                                <Button className="franchisebtn me-2 w-100" type="submit" disabled={loading}>
                                                    {loading ? (
                                                        <div className="spinner-container">
                                                            <Spinner as="span" animation="border" role="status" aria-hidden="true" className="custom-spinner" />
                                                            Submiting
                                                        </div>
                                                    ) : (

                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col lg={4} md={4} sm={6} xs={6}>
                                                <Button className='cancel w-100' type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        resetFormData();
                                                    }}
                                                    disabled={loading}
                                                // onClick={() => {
                                                //     formRef.current?.reset();
                                                // }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body >
                            </Card >
                        </div >
                    </>
                );
            case "Regional":
                return (
                    <>
                        <p className="subhead text-start pdmob">
                            Regional Franchise Partner
                        </p>
                        <div className="mb-5 pdmob">
                            <Card
                                style={{
                                    background: "#a8b3d9",
                                    border: "transparent",
                                    borderRadius: "10px",
                                    padding: "10px 5px",
                                }}
                            >
                                <Card.Body>
                                    {/* onSubmit={handleSubmit} */}
                                    <Form
                                        onSubmit={handleSubmit}
                                        id="franchisepartner"
                                        ref={formRef}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="firstname"
                                                    id="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.firstname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstname}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-none">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="subject"
                                                    id="subject"
                                                    value={sub}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className="fieldstyle"
                                                    name="lastname"
                                                    id="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.lastname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastname}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className="lblstyle">Mobile No.</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="2" md="2" sm="2" xs="2" className="pe-0">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="+91"
                                                        className="fieldstyle"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="10" md="10" sm="10" xs="10">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Mobile No"
                                                        className="fieldstyle"
                                                        name="mobileno"
                                                        id="mobileno"
                                                        value={formData.mobileno}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileno}
                                                        maxLength="10"
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.mobileno}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Label className="lblstyle">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    className="fieldstyle"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Pincode</Form.Label>
                                                <Form.Control
                                                    placeholder="Pincode"
                                                    className="fieldstyle"
                                                    name="pincode"
                                                    id="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pincode}
                                                    maxLength="6"
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        getStateList(formData.pincode);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.pincode}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">State</Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    className="fieldstyle"
                                                    value={formData.state}
                                                    id="state"
                                                    onChange={handleChange}
                                                    name="state"
                                                >
                                                    {statesList.length == 0 ?
                                                        <option value="" disabled>Choose...</option>
                                                        :
                                                        statesList.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg={4} md={4} sm={6} xs={6} className='pe-0'>
                                                <Button className="franchisebtn me-2 w-100" type="submit" disabled={loading}>
                                                    {loading ? (
                                                        <div className="spinner-container">
                                                            <Spinner as="span" animation="border" role="status" aria-hidden="true" className="custom-spinner" />
                                                            Submiting
                                                        </div>
                                                    ) : (

                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col lg={4} md={4} sm={6} xs={6}>
                                                <Button className='cancel w-100' type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        resetFormData();
                                                    }}
                                                    disabled={loading}
                                                // onClick={() => {
                                                //     formRef.current?.reset();
                                                // }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </>
                );
            case "Zonal":
                return (
                    <>
                        <p className="subhead text-start pdmob">Zonal Franchise Partner</p>
                        <div className="mb-5 pdmob">
                            <Card
                                style={{
                                    background: "#a8b3d9",
                                    border: "transparent",
                                    borderRadius: "10px",
                                    padding: "10px 5px",
                                }}
                            >
                                <Card.Body>
                                    {/* onSubmit={handleSubmit} */}
                                    <Form
                                        onSubmit={handleSubmit}
                                        id="franchisepartner"
                                        ref={formRef}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="firstname"
                                                    id="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.firstname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstname}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-none">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="subject"
                                                    id="subject"
                                                    value={sub}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className="fieldstyle"
                                                    name="lastname"
                                                    id="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.lastname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastname}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className="lblstyle">Mobile No.</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="2" md="2" sm="2" xs="2" className="pe-0">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="+91"
                                                        className="fieldstyle"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="10" md="10" sm="10" xs="10">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Mobile No"
                                                        className="fieldstyle"
                                                        name="mobileno"
                                                        id="mobileno"
                                                        value={formData.mobileno}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileno}
                                                        maxLength="10"
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.mobileno}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Label className="lblstyle">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    className="fieldstyle"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Pincode</Form.Label>
                                                <Form.Control
                                                    placeholder="Pincode"
                                                    className="fieldstyle"
                                                    name="pincode"
                                                    id="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pincode}
                                                    maxLength="6"
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        getStateList(formData.pincode);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.pincode}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">State</Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    className="fieldstyle"
                                                    value={formData.state}
                                                    id="state"
                                                    onChange={handleChange}
                                                    name="state"
                                                >
                                                    {statesList.length == 0 ?
                                                        <option value="" disabled>Choose...</option>
                                                        :
                                                        statesList.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg={4} md={4} sm={6} xs={6} className='pe-0'>
                                                <Button className="franchisebtn me-2 w-100" type="submit" disabled={loading}>
                                                    {loading ? (
                                                        <div className="spinner-container">
                                                            <Spinner as="span" animation="border" role="status" aria-hidden="true" className="custom-spinner" />
                                                            Submiting
                                                        </div>
                                                    ) : (

                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col lg={4} md={4} sm={6} xs={6}>
                                                <Button className='cancel w-100' type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        resetFormData();
                                                    }}
                                                    disabled={loading}
                                                // onClick={() => {
                                                //     formRef.current?.reset();
                                                // }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </>
                );
            case "State":
                return (
                    <>
                        <p className="subhead text-start pdmob">State Franchise Partner</p>
                        <div className="mb-5 pdmob">
                            <Card
                                style={{
                                    background: "#a8b3d9",
                                    border: "transparent",
                                    borderRadius: "10px",
                                    padding: "10px 5px",
                                }}
                            >
                                <Card.Body>
                                    {/* onSubmit={handleSubmit} */}
                                    <Form
                                        onSubmit={handleSubmit}
                                        id="franchisepartner"
                                        ref={formRef}
                                        onKeyDown={(e) => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault();
                                            }
                                        }}
                                    >
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="firstname"
                                                    id="firstname"
                                                    value={formData.firstname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.firstname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstname}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12} className="d-none">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name"
                                                    className="fieldstyle"
                                                    name="subject"
                                                    id="subject"
                                                    value={sub}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    className="fieldstyle"
                                                    name="lastname"
                                                    id="lastname"
                                                    value={formData.lastname}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.lastname}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastname}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className="lblstyle">Mobile No.</Form.Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg="2" md="2" sm="2" xs="2" className="pe-0">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="+91"
                                                        className="fieldstyle"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg="10" md="10" sm="10" xs="10">
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Mobile No"
                                                        className="fieldstyle"
                                                        name="mobileno"
                                                        id="mobileno"
                                                        value={formData.mobileno}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mobileno}
                                                        maxLength="10"
                                                        autoComplete="off"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.mobileno}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Label className="lblstyle">Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    className="fieldstyle"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.email}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">Pincode</Form.Label>
                                                <Form.Control
                                                    placeholder="Pincode"
                                                    className="fieldstyle"
                                                    name="pincode"
                                                    id="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.pincode}
                                                    maxLength="6"
                                                    onBlur={(e) => {
                                                        e.preventDefault();
                                                        getStateList(formData.pincode);
                                                    }}
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.pincode}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={6} md={6} sm={12} xs={12}>
                                                <Form.Label className="lblstyle">State</Form.Label>
                                                <Form.Select
                                                    defaultValue="Choose..."
                                                    className="fieldstyle"
                                                    value={formData.state}
                                                    id="state"
                                                    onChange={handleChange}
                                                    name="state"
                                                >
                                                    {statesList.length == 0 ?
                                                        <option value="" disabled>Choose...</option>
                                                        :
                                                        statesList.map((state, index) => (
                                                        <option key={index} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg={4} md={4} sm={6} xs={6} className='pe-0'>
                                                <Button className="franchisebtn me-2 w-100" type="submit" disabled={loading}>
                                                    {loading ? (
                                                        <div className="spinner-container">
                                                            <Spinner as="span" animation="border" role="status" aria-hidden="true" className="custom-spinner" />
                                                            Submiting
                                                        </div>
                                                    ) : (

                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col lg={4} md={4} sm={6} xs={6}>
                                                <Button className='cancel w-100' type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        resetFormData();
                                                    }}
                                                    disabled={loading}
                                                // onClick={() => {
                                                //     formRef.current?.reset();
                                                // }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <Layout>
            <Container fluid>
                <div className="franchiseinauguration businesspartner pt-4">
                    <Row className="justify-content-center">
                        <Col lg="9">
                            <Row className="justify-content-end">
                                <ToastContainer />
                                {/* <Col lg="3" md="3" sm="12" xs="12" className=''>
                                    <p className='head text-start'></p>
                                    <div className='wrap d-lg-block d-md-flex d-sm-flex d-xs-flex'>
                                        <div>
                                            <Button
                                                id="franchiseInauguration"
                                                className={`btnstyle me-2 ${activeButton === "franchiseInauguration" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("franchiseInauguration")}
                                            >
                                                Franchise Partner
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="trainingProgram"
                                                className={`btnstyle me-2 ${activeButton === "trainingProgram" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("trainingProgram")}
                                            >
                                                District Franchise Partner
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="awardRecognition"
                                                className={`btnstyle me-2 ${activeButton === "awardRecognition" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("awardRecognition")}
                                            >
                                                Regional Franchise Partner
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="advertisementVideos"
                                                className={`btnstyle me-2 ${activeButton === "advertisementVideos" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("advertisementVideos")}
                                            >
                                                Zonal Franchise Partner
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="genericMedicineAwareness"
                                                className={`btnstyle me-2 ${activeButton === "genericMedicineAwareness" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("ourWarehouse")}
                                            >
                                                State Franchise Partner
                                            </Button>
                                        </div>
                                    </div>
                                </Col> */}
                                <Col lg="4" md="6" sm="12" xs="12">
                                    {renderContent(type)}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    );
}
