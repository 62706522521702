import React, { useState } from 'react'
import Layout from './Layout'
import santoshshendge from "../assets/images/santoshshendge.png";
import ojal from "../assets/images/administrative/ojal .jpg";
import bhagyashree from "../assets/images/administrative/bhagyashree.jpeg";
import kalpana from "../assets/images/administrative/kalpana.png";
import mithilesh from "../assets/images/administrative/mithilesh.png";
import rohan from "../assets/images/administrative/rohan.png";
import pallvi from "../assets/images/administrative/pallvi.png";
import sarang from "../assets/images/administrative/sarang.jpeg";
import sumit from "../assets/images/administrative/sumit.png";
import akshay from "../assets/images/administrative/akshay.png";
import prajkta from "../assets/images/administrative/prajkta.png";
import roshani from "../assets/images/administrative/roshani.png";
import prathmesh from "../assets/images/administrative/prathmesh.jpg";
import tejaswini from "../assets/images/administrative/tejaswini.jpg";
import deepika from "../assets/images/warehouse/deepika.jpg";
import priyanka from "../assets/images/warehouse/priyanka.jpeg";
import kaushalsoni from "../assets/images/warehouse/kaushalsoni.jpg";
import pramod from "../assets/images/warehouse/pramod.jpg";
import kiran from "../assets/images/warehouse/kiran.jpg";
import yuvrajsingh from "../assets/images/warehouse/yuvrajsingh.jpg";

import Select from 'react-select';
import {
    statedropdown
} from "../helpers";
import {
    Button, Row, Col, Container, Form, Card
} from "react-bootstrap";
export default function AdministrativeTeam() {
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    const defaultOption = { value: 'administrative', label: 'Administrative Team' };
    const [selectedTeam, setSelectedTeam] = useState(defaultOption);

    const handleTeamChange = (selectedOption) => {
        setSelectedTeam(selectedOption);
    };

    const teamOptions = [
        { value: 'administrative', label: 'Administrative Team' },
        { value: 'warehouse', label: 'Warehouse Team' },
    ];

    const teamMembers = {
        administrative: [
            { name: 'Ojal Chordiya', position: 'Account Executive', image: ojal },
            { name: 'Bhagyashree Joshi', position: 'Admin Officer', image: bhagyashree },
            { name: 'Kalpana Kanhere', position: 'PRM Officer', image: kalpana },
            // { name: 'Mithilesh Mali', position: 'IT Support Executive', image: mithilesh },
            // { name: 'Rohan Benare', position: 'Business Development Manager', image: rohan },
            { name: 'Pallavi Pawar', position: 'Business Development Executive', image: pallvi },
            { name: 'Sarang Musande', position: 'Business Development Executive', image: sarang },
            // { name: 'Sumit Mohite', position: 'Digital Marketing Executive', image: sumit },
            { name: 'Akshay Chavan', position: 'Sales Manager', image: akshay },
            { name: 'Prajakta Thakur', position: 'Customer Care Executive', image: prajkta },

            { name: 'Prathmesh Giri', position: 'Video Editor', image: prathmesh },
            { name: 'Tejaswini Shinde', position: 'Content Writer/Digital Marketing Officer', image: tejaswini },
        ],

        warehouse: [
            { name: 'Deepika Jain', position: 'Sales Consultant', image: deepika },
            { name: 'Priyanka Shinde', position: 'Sales Consultant', image: priyanka },
            { name: 'Kaushal Kumar Soni', position: 'Purchase Executive', image: kaushalsoni },
            { name: 'Pramod Modanwal', position: 'Warehouse Manager', image: pramod },
            // { name: 'Kiran Chaudhary', position: 'Purchase Executive', image: kiran },
            { name: 'Yuvaraj Singh', position: 'Logistics Executive', image: yuvrajsingh },
            // { name: 'Laxmi Sahu', position: 'Logistics Executive', image: laxmisahu },
            { name: 'Roshni Wagh', position: 'Sales Executive', image: roshani },
        ],

    };
    return (
        <Layout>
            <Container fluid>
                <div className={`${isSafari == true ? "aboutsafari" : "about-style"}`}>
                    <div className='about p-5'>
                        <Row className="justify-content-center">
                            <Col lg="9">
                                <p className='text-center mb-0'>
                                    Administrative Team
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className='mt-4'>
                        <Row className="justify-content-center">
                            <Col lg="9">
                                <Row>
                                    <Col lg="4" md="4" sm="12" xs="12" className='text-center'>
                                        <img src={santoshshendge} alt="" className='adminstyle' />
                                    </Col>
                                    {/* <Col lg="1"></Col> */}
                                    <Col lg="8" md="8" sm="12" xs="12" className='ps-5'>
                                        <p className='head text-start'>More About Our <span style={{ color: "#2B4BB6" }}>Founder & CEO</span> </p>
                                        <p className='text-style'>
                                            Santosh Shedge Sir, the proud Founder and CEO of Genivis Remedies, has established a remarkable legacy as a leading
                                            figure in India's pharmaceutical sector.Renowned for launching the fastest-growing chain in Generic Medicines and
                                            Manufacturing Distribution since 2016, Santosh Shedge Sir has propelled the company to new heights with over 2000
                                            franchises across Maharashtra, Karnataka, Gujarat and Odisha. With a steadfast commitment to healthcare affordability
                                            and inspired by initiatives like the PM Janaushadhi Yojana Scheme, he has made significant strides in making healthcare
                                            accessible to all.
                                        </p>
                                        <p className='text-style'>
                                            Genivis Remedies thrives on an innovative low-cost franchise model that attracts a diverse range of
                                            entrepreneurs, reflecting Santosh Shedge Sir's vision for a healthier tomorrow. His extensive experience,
                                            including leadership roles in multinational giants like Bajaj Allianz, TATA AIA, Reliance Nippon and
                                            Edelweiss, underscores his capability to lead Genivis Remedies towards its ambitious goal of 10,000
                                            franchises by 2030. Recognized for his pioneering leadership and award-winning innovation, Santosh Shedge Sir
                                            is a distinguished figure in the pharmaceutical industry, dedicated to mentoring and inspiring future leaders.
                                        </p>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className='pt-4' style={{ backgroundColor: "#f9f9f9" }}>
                        <Row className="justify-content-center">
                            <Col lg="9">
                                <Row className="justify-content-center">
                                    <Col lg="8" className='text-center'>
                                        <p className='subhead '>Get to know our team</p>
                                        <p className='text-style'>
                                            We’re committed to building a diverse and inclusive team. Get to know the amazing individuals who are creating our product and supporting our customers.
                                        </p>
                                        <Row className='justify-content-center'>
                                            <Col lg="3">
                                                <Form.Group>
                                                    <Select
                                                        className='selectTo'
                                                        isClearable={true}
                                                        styles={statedropdown}
                                                        placeholder="Administrative Team"
                                                        name="team"
                                                        id="team"
                                                        options={teamOptions}
                                                        onChange={handleTeamChange}
                                                        defaultValue={defaultOption}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row className='mt-4'>
                                    <Col lg="3" md="3" sm="6" xs="6">
                                        <Card style={{ border: "transparent" }}>
                                            <Card.Img variant="top" src={ojal} />
                                            <Card.Body>
                                                <Card.Title>Ojal Chordiya</Card.Title>
                                                <Card.Text>
                                                    Account Executive
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                    <Col lg="3" md="3" sm="6" xs="6">
                                        <Card style={{ border: "transparent" }}>
                                            <Card.Img variant="top" src={pradnya} />
                                            <Card.Body>
                                                <Card.Title>Pradnya Shinde</Card.Title>
                                                <Card.Text>
                                                    Admin Officer
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                    <Col lg="3" md="3" sm="6" xs="6">
                                        <Card style={{ border: "transparent" }}>
                                            <Card.Img variant="top" src={kalpana} />
                                            <Card.Body>
                                                <Card.Title>Kalpana Kanhere</Card.Title>
                                                <Card.Text>
                                                    PRM Officer
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col lg="3" md="3" sm="6" xs="6">
                                        <Card style={{ border: "transparent" }}>
                                            <Card.Img variant="top" src={ojal} />
                                            <Card.Body>
                                                <Card.Title>Ojal Chordiya</Card.Title>
                                                <Card.Text>
                                                    Account Executive
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                    <Col lg="3" md="3" sm="6" xs="6">
                                        <Card style={{ border: "transparent" }}>
                                            <Card.Img variant="top" src={pradnya} />
                                            <Card.Body>
                                                <Card.Title>Pradnya Shinde</Card.Title>
                                                <Card.Text>
                                                    Admin Officer
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                    <Col lg="3" md="3" sm="6" xs="6">
                                        <Card style={{ border: "transparent" }}>
                                            <Card.Img variant="top" src={kalpana} />
                                            <Card.Body>
                                                <Card.Title>Kalpana Kanhere</Card.Title>
                                                <Card.Text>
                                                    PRM Officer
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                    </Col>
                                </Row> */}
                                {selectedTeam && (
                                    <Row className='mt-4'>
                                        {teamMembers[selectedTeam.value].map((member, index) => (
                                            <Col key={index} lg="3" md="3" sm="6" xs="6">
                                                <Card style={{ border: "transparent", backgroundColor: "transparent" }}>
                                                    <Card.Img variant="top" src={member.image} className='admin-img' />
                                                    <Card.Body>
                                                        <Card.Title className='adminhead'>{member.name}</Card.Title>
                                                        <Card.Text className='admintxt'>{member.position}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container >
        </Layout >
    )
}
