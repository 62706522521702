import React, { useEffect } from 'react'
import Layout from './Layout'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import Layer_1 from "../assets/images/Layer_1.png";
import Layer_2 from "../assets/images/Layer_2.png";
import Layer_3 from "../assets/images/Layer_3.png";
import Layer_4 from "../assets/images/Layer_4.png";
import { useLocation } from 'react-router-dom';
import gvtmlogo from "../assets/images/gvtmlogo.png";
export default function AboutUs() {
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <Layout>
            <Container fluid>
                <div className={`${isSafari == true ? "aboutsafari" : "about-style"}`}>
                    <div className='about p-5'>
                        <Row className="justify-content-center">
                            <Col lg="9">
                                <p className='text-center mb-0'>
                                    Welcome To <span>GENIVIS REMEDIES </span>
                                </p>
                                <p className='text-center mb-0'>India’s No. 1 Fastest Growing Generic Brand</p>


                            </Col>
                        </Row>
                    </div>
                    <div className='mt-4'>
                        <Row className="justify-content-center">
                            <Col lg="9">
                                <Row>
                                    <Col lg="4" md="4" sm="12" xs="12">
                                        <p className='head text-start'>About Us</p>
                                        <div className='my-auto'>
                                        <img src={gvtmlogo} className='logostyle mb-4' />
                                        </div>
                                    </Col>
                                    <Col lg="8" md="8" sm="12" xs="12">
                                        <p className='text-style'>
                                            Genivis Remedies (I) Pvt. Ltd. is a dynamic and innovative pharmaceutical company dedicated
                                            to enhancing healthcare through our extensive network of generic medicine shops.
                                            We are on a mission to make high-quality, affordable generic medicines accessible to
                                            everyone and we are continuously expanding our business by appointing new franchises
                                            across the country.
                                        </p>
                                        <p className='text-style'>
                                            With a strong presence in Maharashtra, Karnataka and Goa, Genivis Remedies proudly boasts over 2000 registered stores.
                                            This robust foundation is just the beginning, as we set our sights on tapping into the PAN India market. Our strategic
                                            growth plans include launching massive programs aimed at improving pharmaceutical manufacturing processes and drug delivery
                                            models, ensuring that we remain at the forefront of the healthcare industry.
                                        </p>
                                        <p className='text-style'>
                                            Our commitment to excellence drives us to continually innovate and improve our offerings. For more information about
                                            generic medicines and our comprehensive approach to healthcare, explore our resources and initiatives. By partnering with Genivis
                                            Remedies, franchisees gain access to a wealth of resources, comprehensive support and a trusted brand that consumers rely on.
                                            Together, we are building a healthier future for all.
                                        </p>
                                        <p className='text-style'>
                                            Join us in our journey towards making affordable healthcare a reality for every Indian. Explore the
                                            opportunities with Genivis Remedies (I) Pvt. Ltd., where quality meets affordability and growth
                                            knows no bounds. Our franchise business model ensures that partners benefit from extensive support
                                            and the backing of a renowned brand in the pharmaceutical industry.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className='aboutimg mt-4' id="vision-mission">
                        <Row className='justify-content-center'>
                            <Col lg="9">
                                <Row className='justify-content-center'>
                                    <Col lg="3" md="3" sm="12" xs="12" className='text-center'>
                                        <img src={Layer_1} className='imgstyle' />
                                        <p className='visionhead '>Our Vision</p>
                                        <p className='visiontext'>
                                            To build the strongest chain of generic medicines franchises with an excellent customer
                                            service followed by a social cause.
                                        </p>
                                    </Col>
                                    <Col lg="3" md="3" sm="12" xs="12" className='text-center'>
                                        <img src={Layer_2} className='imgstyle' />
                                        <p className='visionhead '>Our Mission</p>
                                        <p className='visiontext'>
                                            To provide access to quality Branded Generic medicines at an
                                            affordable price to the citizens of India by expanding our presence in multiple States of India.
                                        </p>
                                    </Col>
                                    <Col lg="3" md="3" sm="12" xs="12" className='text-center'>
                                        <img src={Layer_3} className='imgstyle' />
                                        <p className='visionhead '>Our Values</p>
                                        <p className='visiontext'>
                                            We ensure high quality public drugs which are procured for supply to our generic
                                            medicine franchisees from WHO/Good Manufacturing Practice (GMP) Current Good Manufacturing
                                            Practice and CPU's manufacturers. Every batch of drugs obtained is tested thereby ensuring medicinal
                                            consistency, health and effectiveness and compliance with the appropriate standards.
                                        </p>
                                    </Col>
                                    <Col lg="3" md="3" sm="12" xs="12" className='text-center'>
                                        <img src={Layer_4} className='imgstyle' />
                                        <p className='visionhead '>Our Strength</p>
                                        <p className='visiontext'>
                                            To maintain the highest range of branded generic medicines under one roof at lowest rates.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container >
        </Layout >
    )
}

