import React from 'react'
import Layout from "./Layout";
import { Button, Row, Col, Container, Modal, Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faHandPointDown,
    faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import pin from "../assets/images/pin.png";
import bag from "../assets/images/bag.png";
import clock from "../assets/images/clock.png";
import money from "../assets/images/money.png";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import { useNavigate } from 'react-router-dom';
export default function Jobs() {
    const navigate = useNavigate();

    const isSafari =
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor);
    return (
        <Layout>
            <div className={`${isSafari == true ? "aboutsafari" : "about-style"}`}>
                <div className="about p-5">
                    <Row className="justify-content-center">
                        <Col lg="9">
                            <p className="text-center mb-0">Jobs</p>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-center">
                    <Col lg="9" className="pdmob">
                        <Table className='mt-4'>
                            <thead>
                                <tr>
                                    <th style={{ width: "70%", backgroundColor: "#E6E9F5" }}>Job Title</th>

                                    <th style={{ backgroundColor: "#E6E9F5" }}>Posted On</th>

                                    <th style={{ backgroundColor: "#E6E9F5" }}>Location</th>
                                </tr>
                            </thead>
                        </Table>
                        <Card
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/job1");
                            }}
                        >
                            <Table responsive>

                                <tbody style={{ border: "1px solid transparent" }} className=''>
                                    <tr>
                                        <td className='subhead' style={{ width: "70%" }}>Senior Manager - Sales & Marketing</td>
                                        <td ></td>
                                        <td > </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: "70%" }} className='text-style'>
                                            <img src={bag} className='me-1' />
                                            5 - 6 Years in sales, preferably in the pharmaceutical industry</td>

                                        <td className='text-style' ><img src={clock} className='me-1' />22/07/2024</td>
                                        <td className='text-style'>  <img src={pin} className='me-1' />Pune </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: "70%" }} className='text-style'>
                                            <img src={money} className='me-1' />
                                            5 - 6 LPA (depending on experience and qualifications)</td>

                                        <td ></td>
                                        <td ></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                        <Card className='my-4'
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/job2");
                            }}
                        >
                            <Table responsive>
                                <tbody style={{ border: "1px solid transparent" }}>
                                    <tr>
                                        <td className='subhead' style={{ width: "70%" }}>Telecaller</td>
                                        <td ></td>
                                        <td > </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: "70%" }} className='text-style'>
                                            <img src={bag} className='me-1' />
                                            1 - 2 years of Experience as Telecaller</td>

                                        <td className='text-style'><img src={clock} className='me-1' />22/07/2024</td>
                                        <td className='text-style'>  <img src={pin} className='me-1' />Hadapsar,Pune </td>

                                    </tr>
                                    <tr>
                                        <td style={{ width: "70%" }} className='text-style'>
                                            <img src={money} className='me-1' />
                                            1.8 LPA – 2 LPA</td>

                                        <td ></td>
                                        <td ></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>

            </div>
        </Layout>
    )
}
