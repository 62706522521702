import React, { useRef, useEffect } from 'react'
import Layout from './Layout'
import { Button, Row, Col, Container, Modal, Card, Table, CardTitle, CardBody } from "react-bootstrap";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import pin from "../assets/images/pin.png";
import bag from "../assets/images/bag.png";
import money from "../assets/images/money.png";
import clock from "../assets/images/clock.png";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faShare, faShareNodes, faTag } from '@fortawesome/free-solid-svg-icons';
export default function Job1() {
    const cardRef = useRef(null);
    const containerRef = useRef(null);
    const footerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const card = cardRef.current;
            const container = containerRef.current;
            const footer = footerRef.current;

            if (!card || !container || !footer) return;

            const containerRect = container.getBoundingClientRect();
            const footerRect = footer.getBoundingClientRect();
            const cardRect = card.getBoundingClientRect();

            if (containerRect.top <= 20 && footerRect.top > cardRect.bottom) {
                card.style.position = 'fixed';
                card.style.top = '20px'; // Adjust based on your needs
            } else if (footerRect.top <= cardRect.bottom) {
                card.style.position = 'absolute';
                card.style.top = `${footerRect.top - containerRect.top - cardRect.height}px`;
            } else {
                card.style.position = 'relative';
                card.style.top = '0';
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate();
    const isSafari =
        /Safari/.test(navigator.userAgent) &&
        /Apple Computer/.test(navigator.vendor);
    return (
        <Layout>
            <div className={`${isSafari == true ? "aboutsafari" : "about-style"}`}>
                <div className="about p-5">
                    <Row className="justify-content-center">
                        <Col lg="9">
                            <p className="text-center mb-0">Jobs</p>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-center">
                    <Col lg="9" className="pdmob">
                        <Row className='my-4'>
                            <Col>
                                <Button variant="outline-secondary" className='btnstyle'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/jobs");
                                    }}
                                >Back to job list</Button>
                            </Col>
                        </Row>
                        <Row className='mb-4' ref={containerRef}>
                            <Col lg="8" md="8" sm="12" xs="12">
                                <Card className='mb-2'>
                                    <Table responsive>

                                        <tbody style={{ border: "1px solid transparent" }} >
                                            <tr>
                                                <td className='subhead'>Senior Manager - Sales & Marketing</td>
                                            </tr>
                                            <tr>
                                                <td className='text-style'>
                                                    <img src={bag} className='me-1' />
                                                    5 - 6 Years in sales, preferably in the pharmaceutical industry</td>
                                            </tr>
                                            <tr>
                                                <td className='text-style'>
                                                    <img src={money} className='me-1' />
                                                    5 - 6 LPA (depending on experience and qualifications)</td>
                                            </tr>
                                            <tr>
                                                <td className='text-style'> <img src={clock} className='me-1' />
                                                    22/07/2024</td>
                                            </tr>
                                            <tr>
                                                <td className='text-style'>
                                                    <img src={pin} className='me-1' />Pune
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                            <Col lg="4" md="4" sm="12" xs="12" >
                                <Card style={{ backgroundColor: "#EFF7FD", border: "1px solid #C9E3F5" }} className='sticky-card mb-2' ref={cardRef}>
                                    <CardBody>
                                        <p className='sendmail'>   Send us your resume on our email address or contact for more details</p>
                                        <a href="tel:+919145009191" style={{ textDecoration: "none" }}>
                                            <Row >
                                                <p className="contacthead mb-0">Call Us:</p>
                                                <Col lg="1" md="1" sm="1" xs="1" className="pe-0 text-end">
                                                    <img src={call} className="contactico me-2" />
                                                </Col>
                                                <Col lg="11" md="11" sm="11" xs="11">
                                                    <p className="text-style" style={{ fontWeight: "600" }}>+91 9145009191</p>
                                                </Col>
                                            </Row>
                                        </a>
                                        <a href="mailto:prm@genivis.in" style={{ textDecoration: "none" }}>
                                            <Row>
                                                <p className=" contacthead mb-0">Mail ID:</p>
                                                <Col lg="1" md="1" sm="1" xs="1" className="pe-0 text-end">
                                                    <img src={gmail} className="contactico me-2" />
                                                </Col>
                                                <Col lg="11" md="11" sm="11" xs="11">
                                                    <p className="text-style" style={{ fontWeight: "600" }}>prm@genivis.in</p>
                                                </Col>
                                            </Row>
                                        </a>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="8" md="8" sm="12" xs="12">
                                <p className='subhead'>Key Responsibilities</p>
                                <ul className='text-style'>
                                    <li>
                                        Develop and implement strategic sales and marketing plans to achieve company objectives.
                                    </li>
                                    <li>
                                        Lead a team of sales and marketing professionals, providing guidance and support to ensure targets are met.
                                    </li>
                                    <li>
                                        Build and maintain strong relationships with key stakeholders including healthcare professionals, distributors, and regulatory bodies.
                                    </li>
                                    <li>
                                        Monitor market trends and competitor activities to identify opportunities for growth.
                                    </li>
                                    <li>
                                        Collaborate cross-functionally with other departments to ensure alignment on business goals.
                                    </li>
                                </ul>
                                <p className='subhead'>Key Responsibilities</p>
                                <ul className='text-style'>
                                    <li>
                                        Proven experience of 5 - 6 years in sales, with a strong track record of success in the pharmaceutical industry.
                                    </li>
                                    <li>
                                        MBA Degree.
                                    </li>
                                    <li>
                                        Pharmacy background is mostly preferred.
                                    </li>
                                    <li>
                                        Excellent leadership and communication skills.
                                    </li>
                                    <li>
                                        Ability to thrive in a fast-paced, dynamic environment.
                                    </li>
                                    <li>
                                        Strong understanding of pharmaceutical sales processes and regulations.
                                    </li>
                                </ul>
                                <hr style={{ marginBottom: "0px" }}></hr>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col lg="8" md="8" sm="12" xs="12" className=' d-flex my-auto'>
                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                    <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                </a>
                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                    <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                </a>
                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                </a>
                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                </a>
                                <div className='d-flex shareit'>
                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Layout >
    )
}
